import React from 'react';

import CheckCircle from 'assets/icons/CheckCircle.svg';
import { InfoMessage } from 'components/InfoMessage';
import { useTranslation } from 'react-i18next';

import styles from './ClosePage.module.scss';

export const ClosePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['close-page']}>
      <InfoMessage
        icon={CheckCircle}
        iconStrokeColor="accent"
        size="xl"
        body={t('close.body')}
      />
    </div>
  );
};
