import React from 'react';

import cx from 'classnames';

import styles from './Tabs.module.scss';
import { Text } from '..';

export interface TabItemProps {
  isActive: boolean;
  onClick: () => void;
}

export const TabItem: React.FC<TabItemProps> = ({
  isActive,
  onClick,
  children,
}) => {
  const classes = cx(styles['tabs__tab-item'], {
    [styles['tabs__tab-item--active']]: isActive,
  });

  return (
    <button className={classes} onClick={onClick}>
      <Text size="sm">{children}</Text>
    </button>
  );
};
