import { apiClient } from 'api/utils';

import FinalReportApi from './FinalReportAPI.constants';

interface FinalReportProps {
  formData: FormData;
  workId: string;
}

export const postFinalReport = async ({
  formData,
  workId,
}: FinalReportProps): Promise<void> => {
  return apiClient.post(FinalReportApi(workId).FINAL_REPORT, formData);
};
