import React from 'react';

import CrossCircle from 'assets/icons/CrossCircle.svg';
import cx from 'classnames';
import Compressor from 'compressorjs';
import { format } from 'date-fns';

import styles from './ImageUpload.module.scss';
import { Label, Icon } from '..';

export interface ImageUploadItem {
  imageSource: string;
  date: string;
  file: File | Blob;
}

export interface ImageUploadProps {
  imageList: ImageUploadItem[];
  setImageList: (imageList: ImageUploadItem[]) => void;
  multiple?: boolean;
  isDisabled?: boolean;
}

const compress = (file: File) =>
  new Promise<File | Blob>(
    (resolve, reject) =>
      new Compressor(file, {
        quality: 0.8,
        width: 1200,

        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      }),
  );

export const ImageUpload: React.FC<ImageUploadProps> = ({
  imageList,
  setImageList,
  multiple,
  isDisabled,
}) => {
  const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files?.length) {
      const images = [];

      const allFiles = Array.from(files);

      for (const file of allFiles) {
        try {
          const compressedFile = await compress(file);

          images.push({
            file: compressedFile,
            imageSource: URL.createObjectURL(compressedFile),
            date: format(new Date(), 'dd MMM yyyy, hh:mm'),
          });
        } catch (error) {
          console.error(error);
        }
      }

      const newImageList = [...imageList, ...images];
      setImageList(newImageList);
    }
  };

  const handleRemove = (source: string) => {
    const newImageList = imageList.filter(
      ({ imageSource }) => imageSource !== source,
    );
    setImageList(newImageList);
  };

  return (
    <div
      className={cx(styles['image-upload'], {
        [styles['image-upload--disabled']]: isDisabled,
      })}
      data-testid="image-upload-container"
    >
      <Label
        size="lg"
        inputId="image-upload-input"
        className={styles['image-upload__label']}
      >
        Tap here to upload your images
      </Label>
      <input
        type="file"
        className={styles['image-upload__input']}
        id="image-upload-input"
        data-testid="image-upload-input"
        accept="image/png, image/jpeg"
        onChange={handleOnChange}
        multiple={multiple}
      />
      <ul>
        {imageList.map(({ imageSource, date }) => (
          <li
            key={`${imageSource}-${date}`}
            className={styles['image-upload__item']}
          >
            <img
              src={imageSource}
              alt={imageSource}
              className={styles['image-upload__item-image']}
            />
            <p className={styles['image-upload__item-text']}>{date}</p>
            <button
              onClick={() => handleRemove(imageSource)}
              className={styles['image-upload__item-btn']}
              data-testid={`${imageSource}-delete`}
            >
              <Icon icon={CrossCircle} size="md" fillColor="negative" />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
