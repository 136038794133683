import React from 'react';

import { Text, Heading, Icon, IconColorVariant } from 'components/common';

import styles from './InfoMessage.module.scss';

interface InfoMessageProps {
  title?: string;
  body?: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  iconFillColor?: IconColorVariant;
  iconStrokeColor?: IconColorVariant;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

export const InfoMessage: React.FC<InfoMessageProps> = ({
  title,
  body,
  icon,
  iconFillColor,
  iconStrokeColor,
  size = 'md',
}) => {
  return (
    <div className={styles['info-message']}>
      {icon && (
        <Icon
          testId="info-message-icon"
          icon={icon}
          fillColor={iconFillColor}
          strokeColor={iconStrokeColor}
          size={size}
        />
      )}
      <Heading tag="h4">{title}</Heading>
      <Text size="lg">{body}</Text>
    </div>
  );
};
