import React from 'react';

import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Button as ReakitUIButton } from 'reakit/Button';

import styles from './Button.module.scss';
import { Spinner } from '../Spinner';
import { Text } from '../Text';

export type ButtonAppearance = 'solid' | 'outline' | 'ghost';
export type ButtonVariant = 'ui' | 'accent' | 'positive' | 'negative';
export type ButtonSize = 'sm' | 'md' | 'lg';
export type ButtonRadius = 'square' | 'rounded' | 'pill';
type ButtonType = 'button' | 'reset' | 'submit';

export interface ButtonProps {
  type?: ButtonType;
  appearance?: ButtonAppearance;
  variant?: ButtonVariant;
  size?: ButtonSize;
  radius?: ButtonRadius;
  fullSize?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  label?: string;
  testId?: string;
  className?: string;
  form?: string;
  link?: { to: string; target?: string; rel?: string };
  onClick?: (event: React.MouseEvent) => void;
}

export const Button: React.FC<ButtonProps> = ({
  type,
  appearance = 'solid',
  variant = 'accent',
  size = 'md',
  radius = 'rounded',
  fullSize,
  disabled = false,
  isLoading,
  label,
  testId,
  className,
  link,
  form,
  children,
  onClick,
}) => {
  const buttonClasses = cx(
    styles['btn'],
    styles[`btn--${appearance}--${variant}`],
    styles[`btn--${size}`],
    styles[`btn--${radius}`],
    {
      [`${styles[`btn--fullsize`]}`]: fullSize,
      [`${styles[`btn--disabled`]}`]: disabled || isLoading,
      [`${className}`]: className,
    },
  );

  const linkClasses = cx(
    `${styles['link']}`,
    [`${styles[`link--${variant}`]}`],
    {
      [`${styles[`link--${size}`]}`]: size,
      [`${styles[`link--${radius}`]}`]: radius,
      [`${className}`]: className,
    },
  );

  const getSpinnerAppearance = () => {
    if (appearance === 'solid') {
      return 'light';
    }

    return 'dark';
  };

  const button = (
    <ReakitUIButton
      className={buttonClasses}
      type={type || 'button'}
      disabled={disabled || isLoading}
      onClick={onClick}
      data-testid={testId}
      form={form}
    >
      {isLoading ? (
        <Spinner
          size={size}
          variant={variant}
          appearance={getSpinnerAppearance()}
          testId={`spinner-${testId}`}
        />
      ) : null}
      <Text
        tag="span"
        className={cx(`${styles['btn__text']}`, {
          [`${styles[`btn__text--loading`]}`]: isLoading,
        })}
        size={size}
        testId={testId && `${testId}-label`}
      >
        {children ?? label}
      </Text>
    </ReakitUIButton>
  );

  return (
    <>
      {link ? (
        <Link
          data-testid={testId}
          className={linkClasses}
          to={link.to}
          target={link.target}
          rel={link.rel}
        >
          {children ?? (
            <Text
              tag="span"
              className={cx(`${styles['link__text']}`)}
              size={size}
              testId={`${testId}-link-label`}
            >
              {label}
            </Text>
          )}
        </Link>
      ) : (
        button
      )}
    </>
  );
};
