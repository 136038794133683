import { apiClient } from 'api/utils';
import { ApiDriverList } from 'models/DriverList';

import DriverList from './DriverList.constants';

export const getDriverList = async (): Promise<ApiDriverList> => {
  const res = await apiClient.get(DriverList.GET_DRIVER_LIST);

  return res.data;
};
