import React, { useEffect } from 'react';

import { Button } from 'components/common';
import { PageContent } from 'components/PageContent';
import { LayoutWithStepper } from 'containers/LayoutWithStepper';
import {
  ACCESS,
  ACCESS_ISSUES,
  BEFORE_PHOTOS,
  GC,
} from 'containers/Routes/Routes.constants';
import { useStepperContext } from 'contexts/StepperContext';
import { useAppMode } from 'hooks/useAppMode';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './SiteAccessPage.module.scss';

export const SiteAccessPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setCurrentStep } = useStepperContext();
  const { appMode } = useAppMode();

  const handleClick = (hasAccess: boolean) => {
    history.push(hasAccess ? BEFORE_PHOTOS : ACCESS_ISSUES);
  };

  useEffect(() => {
    setCurrentStep(ACCESS);
  }, [setCurrentStep]);

  return (
    <LayoutWithStepper>
      <div className={styles['site-access']} data-testid="site-access">
        <PageContent
          step={t('siteAccess.step', { step: appMode === GC ? '1' : '2' })}
          heading={t('siteAccess.title')}
          body={t('siteAccess.body')}
        />
        <div className={styles['site-access__actions']}>
          <Button
            label={t('siteAccess.actionYes')}
            onClick={() => handleClick(true)}
            testId="site-access-yes"
          />
          <Button
            label={t('siteAccess.actionNo')}
            appearance="outline"
            onClick={() => handleClick(false)}
            testId="site-access-no"
          />
        </div>
      </div>
    </LayoutWithStepper>
  );
};
