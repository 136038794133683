import { apiClient } from 'api/utils';

import AccessIssueApi from './AccessIssueAPI.constants';

interface AccessIssueProps {
  formData: FormData;
  workId: string;
}

export const postAccessIssue = async ({
  formData,
  workId,
}: AccessIssueProps): Promise<void> => {
  return apiClient.post(AccessIssueApi(workId).BY_WORK_ID, formData);
};
