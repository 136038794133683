import React from 'react';

import {
  GoogleMap,
  Polygon,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { Spinner } from 'components/common';
import { HazardPoint, KMLMap } from 'models/KMLMap';

import styles from './SiteAreas.module.scss';

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const options = {
  fillColor: '#ffaa00',
  fillOpacity: 0.5,
  strokeColor: '#ffaa00',
  strokeWeight: 2,
};

export interface SiteAreasProps {
  center?: google.maps.LatLngLiteral;
  polygons?: KMLMap;
  hazards?: HazardPoint[];
}

export const SiteAreas: React.FC<SiteAreasProps> = ({
  center,
  polygons,
  hazards,
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.GOOGLE_MAPS_API as string,
  });

  if (loadError) {
    return <div data-testid="map-error">Error loading map</div>;
  }

  return isLoaded ? (
    <GoogleMap mapContainerStyle={mapContainerStyle} zoom={19} center={center}>
      {polygons?.map((polygon, i) => (
        <Polygon key={i} paths={polygon.points} options={options} />
      ))}
      {hazards?.map((point) => (
        <Marker
          key={point.name}
          label={{
            text: point.name,
            className: styles.label,
            fontSize: '12px',
          }}
          position={{ lat: point.lat, lng: point.lng }}
          icon="/map_hazard.png"
        />
      ))}
    </GoogleMap>
  ) : (
    <Spinner testId="site-areas-loading" />
  );
};
