import React from 'react';

import { Button } from 'components/common';

import styles from './ActionFooter.module.scss';

interface ActionFooterProps {
  label: string;
  testId?: string;
  form?: string;
  disabled?: boolean;
  onClick?: () => void;
  onCancel?: () => void;
}

export const ActionFooter: React.FC<ActionFooterProps> = ({
  label,
  testId,
  disabled,
  form,
  onClick,
  onCancel,
}) => {
  return (
    <div className={styles['action-footer']}>
      <Button
        type="submit"
        form={form}
        onClick={onClick}
        label={label}
        testId={testId}
        disabled={disabled}
        fullSize
      />
      {onCancel && (
        <Button
          appearance="outline"
          label={'Cancel'}
          fullSize
          className={styles['action-footer__btn-cancel']}
          onClick={onCancel}
          testId={`${testId}-cancel`}
        />
      )}
    </div>
  );
};
