import { Job, ApiJob } from 'models/Job';

export const mapDriverWorkResponse = (response: ApiJob): Job => {
  const job = response.data;
  const { site, report } = job;

  return {
    id: job.id,
    date: new Date(job.date * 1000), // Server returns seconds, JS wants ms
    disablePhoto: site.disable_photo,
    minimumPhotos: job.minimum_required_photos,
    customerName: site.customer.name,
    name: site.name,
    siteLocation: {
      lat: site.latitude,
      lng: site.longitude,
    },
    kmlMap: site.kml,
    alternativeMaps: site.map_urls,
    notes: site.notes,
    street: site.street,
    padlock_code: site.padlock_code,
    hazards: site.hazards,
    existingReport: report
      ? {
          completed_step: report?.data.completed_step,
          driver_id: report?.data.driver_id,
          start_date_time: report?.data.start_date_time,
          end_date_time: report?.data.end_date_time,
        }
      : null,
  };
};
