import React from 'react';

import { getShareWorkToken } from 'api/ShareWorkAPI';
import Notes from 'assets/icons/Notes.svg';
import Share from 'assets/icons/Share.svg';
import SiteMap from 'assets/icons/SiteMap.svg';
import cx from 'classnames';
import { SiteMapModal } from 'components/SiteMapModal';
import { SiteNotesModal } from 'components/SiteNotesModal';
import { GC } from 'containers/Routes/Routes.constants';
import { useAppMode } from 'hooks/useAppMode';
import { useWorkId } from 'hooks/useWorkId';
import { useTranslation } from 'react-i18next';

import styles from './Stepper.module.scss';
import { Label, IconButton } from '..';

export interface StepperProps {
  testId?: string;
  title?: string;
  subtitle?: string;
  steps: string[];
  currentStep: string;
  showNotes?: boolean;
}

interface StepProps {
  isCurrent: boolean;
  isPast: boolean;
  value: number;
}

const Step: React.FC<StepProps> = ({ value, isCurrent, isPast }) => {
  const classes = cx(styles['stepper__step'], {
    [styles['current-step']]: isCurrent,
    [styles['past-step']]: isPast,
  });
  return (
    <Label size="sm" testId={`step-${value}`} className={classes}>
      {value}
    </Label>
  );
};

export const Stepper: React.FC<StepperProps> = ({
  title,
  subtitle,
  steps,
  currentStep,
  testId,
  showNotes,
}) => {
  const { t } = useTranslation();
  const { appMode } = useAppMode();
  const { workId } = useWorkId();

  const [showNotesModal, setShowNotesModal] = React.useState(false);
  const [showMapModal, setShowMapModal] = React.useState(false);

  const onShareClick = async () => {
    const { token } = await getShareWorkToken(workId);

    const url = `${window.location.origin}?token=${token}&workId=${workId}`;
    const title = t('stepper.shareTitle');
    const text = t('stepper.shareText', {
      url,
    });

    console.log('Share URL:', url);
    if (process.env.NODE_ENV === 'development') {
      console.log('Writing URL to clipboard');
      navigator.clipboard.writeText(url);
    }

    try {
      if (navigator.share) {
        await navigator.share({ title, text });
      } else if (window.Android?.share) {
        window.Android.share(`${title}\n\n${text}`);
      } else {
        alert(t('stepper.shareError'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles['stepper']} data-testid={testId}>
      {appMode === GC && (
        <div className={styles['stepper__share']}>
          <IconButton
            icon={Share}
            iconFillColor="blanc"
            onClick={onShareClick}
            iconSize="xxl"
          />
        </div>
      )}
      <Label className={styles['stepper__title']} size="lg">
        {title}
      </Label>
      <Label className={styles['stepper__subtitle']} size="sm">
        {subtitle}
      </Label>
      <div className={styles['stepper__steps']}>
        {steps.map((step, i) => (
          <Step
            key={`step-${i + 1}`}
            value={i + 1}
            isCurrent={step === currentStep}
            isPast={i < steps.indexOf(currentStep)}
          />
        ))}
      </div>
      <div className={styles['stepper__links']}>
        {showNotes && (
          <IconButton
            icon={Notes}
            iconFillColor="accent"
            label={t('stepper.notesLabel')}
            labelColor="blanc"
            onClick={() => setShowNotesModal(true)}
          />
        )}
        <IconButton
          icon={SiteMap}
          iconFillColor="positive"
          label={t('stepper.mapLabel')}
          labelColor="blanc"
          onClick={() => setShowMapModal(true)}
        />
      </div>
      {showNotesModal && (
        <SiteNotesModal closeModal={() => setShowNotesModal(false)} />
      )}
      {showMapModal && (
        <SiteMapModal closeModal={() => setShowMapModal(false)} />
      )}
    </div>
  );
};
