import React from 'react';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import styles from './AlternativeMaps.module.scss';

interface AlternativeMapsProps {
  maps: string[];
}

export const AlternativeMaps: React.FC<AlternativeMapsProps> = ({ maps }) => {
  return (
    <div className={styles['alternative-maps']}>
      {maps?.map((file, i) => {
        const fileExtension = file.split('.').pop();
        return fileExtension === 'pdf' ? (
          <Document key={file} file={file}>
            <Page pageNumber={1} />
          </Document>
        ) : (
          <img key={file} alt={`Alternative map - ${i}`} src={file} />
        );
      })}
    </div>
  );
};
