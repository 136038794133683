import React from 'react';

import Warning from 'assets/icons/Warning.svg';
import { InfoMessage } from 'components/InfoMessage';
import { ContainerWithActionFooter } from 'containers/ContainerWithActionFooter';
import { CLOSE, GC } from 'containers/Routes/Routes.constants';
import { useAppMode } from 'hooks/useAppMode';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './ExpiredPage.module.scss';

export const ExpiredPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { appMode } = useAppMode();

  const onCloseClicked = () => {
    if (window.Android?.close) {
      window.Android.close();
    } else {
      history.push(CLOSE);
    }
  };

  return (
    <ContainerWithActionFooter
      label={t('issueReported.button')}
      actionTestId="issue-reported-close"
      onClick={onCloseClicked}
      hideButton={appMode !== GC}
    >
      <div className={styles['expired-page']}>
        <InfoMessage
          icon={Warning}
          iconFillColor="accent"
          size="xxl"
          title={t('expired.title')}
          body={t('expired.body')}
        />
      </div>
    </ContainerWithActionFooter>
  );
};
