export const ACCESS = '/access';
export const ACCESS_ISSUES = '/access-issues';
export const AFTER_PHOTOS = '/after-photos';
export const BEFORE_PHOTOS = '/before-photos';
export const DETAILS = '/details';
export const ISSUE_REPORTED = '/issue-reported';
export const REVIEW_WORK = '/review-work';
export const SUBMITTED = '/submitted';
export const WORK_IN_PROGRESS = '/work-in-progress';
export const CLOSE = '/close';
export const SHARED = '/shared';
export const GC = 'GC';
export const TEMP = 'TEMP';
export const TEST = '/test-url';
