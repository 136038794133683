import React from 'react';

import cx from 'classnames';

import styles from './Label.module.scss';

interface LabelProps {
  className?: string;
  inputId?: string;
  size?: 'sm' | 'md' | 'lg';
  testId?: string;
}

export const Label: React.FC<LabelProps> = ({
  className,
  inputId,
  size = 'md',
  testId,
  children,
}) => {
  const labelClasses = cx(styles['label'], styles[`label-${size}`], className);

  return (
    <label htmlFor={inputId} className={labelClasses} data-testid={testId}>
      {children}
    </label>
  );
};
