import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ObjectSchema } from 'yup';

export const accessIssuesSchema = (
  t: TFunction,
  disablePhoto?: boolean,
): ObjectSchema =>
  Yup.object().shape({
    reason: Yup.string().required(),
    message: Yup.string().when('reason', {
      is: t('accessIssues.reasons.other'),
      then: Yup.string().min(2).required(),
    }),
    media: Yup.array()
      .notRequired()
      .when('disablePhoto', {
        is: () => disablePhoto !== true,
        then: (rule: { min: (argument: number) => number }) => rule.min(1),
      }),
  });
