import React from 'react';

import { yupResolver } from '@hookform/resolvers';
import cx from 'classnames';
import {
  Heading,
  InputText,
  Modal,
  Checkbox,
  ComboBox,
} from 'components/common';
import { ContainerWithActionFooter } from 'containers/ContainerWithActionFooter';
import { useAccountContext } from 'contexts/AccountContext';
import { useDriverListContext } from 'contexts/DriverListContext';
import { useReportContext } from 'contexts/ReportContext';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './AddDriverModal.module.scss';
import { addDriverSchema } from './addDriverSchema';

interface AddDriverModalProps {
  closeModal: () => void;
}

interface FormDataValue {
  driver: { label: string; value: number } | null;
  firstName: string;
  lastName: string;
  safetyCheck: boolean;
}

export const AddDriverModal: React.FC<AddDriverModalProps> = ({
  closeModal,
}) => {
  const { t } = useTranslation();
  const { addAdditionalDriver, additionalDrivers } = useReportContext();
  const { driverList } = useDriverListContext();
  const { account } = useAccountContext();

  const { register, handleSubmit, formState, control, errors, watch } = useForm(
    {
      resolver: yupResolver(addDriverSchema()),
      mode: 'onChange',
      defaultValues: {
        safetyCheck: false,
        driver: null,
        firstName: '',
        lastName: '',
      },
    },
  );

  const onFormSubmit = (data: FormDataValue) => {
    // If an id exists it takes precedence over firstname lastname
    const driverIdentity = data.driver?.value
      ? { id: data.driver.value }
      : { first_name: data.firstName, last_name: data.lastName };

    addAdditionalDriver({ ...driverIdentity, start_date_time: new Date() });

    closeModal();
  };

  const selectItems = driverList
    ?.filter((item) => {
      // Filter out the user and already selected drivers
      return !(
        item.id === account?.id ||
        additionalDrivers.find((driver) => driver.id === item.id)
      );
    })
    .map((item) => ({
      label: item.name,
      value: item.id,
    }));

  const driverSelected = Boolean(watch('driver'));
  const firstNameEntered = Boolean(watch('firstName'));
  const lastNameEntered = Boolean(watch('lastName'));

  return (
    <Modal title={t('addDriver.title')} testId="add-driver-modal" hideHeader>
      <ContainerWithActionFooter
        form="add-driver-form"
        label={t('addDriver.action')}
        disabled={!formState.isValid}
        actionTestId="add-driver-submit"
        onCancel={closeModal}
      >
        <form
          id="add-driver-form"
          onSubmit={handleSubmit(onFormSubmit)}
          className={styles['add-driver']}
          data-testid={'add-driver-page'}
        >
          <Heading className={styles['add-driver__heading']}>
            {t('addDriver.title')}
          </Heading>

          <Controller
            name="driver"
            control={control}
            render={({ onChange, value }) => (
              <ComboBox
                id="add-driver-select"
                testId="add-driver-select"
                placeholder={t('addDriver.placeholder')}
                items={selectItems ?? []}
                onChange={onChange}
                value={value}
                showReset={Boolean(value)}
                disabled={firstNameEntered || lastNameEntered}
              />
            )}
          />

          <div
            className={cx(
              styles['add-driver__divider'],
              styles['add-driver__divider--text'],
            )}
          >
            {t('addDriver.or')}
          </div>
          <div className={styles['add-driver__input']}>
            <InputText
              label={t('addDriver.firstName')}
              name="firstName"
              ref={register}
              validationError={errors.firstName?.message}
              disabled={driverSelected}
            />
          </div>
          <div className={styles['add-driver__input']}>
            <InputText
              label={t('addDriver.lastName')}
              name="lastName"
              ref={register}
              validationError={errors.lastName?.message}
              disabled={driverSelected}
            />
          </div>

          <div className={styles['add-driver__divider']} />

          <Controller
            name="safetyCheck"
            control={control}
            render={({ onChange, value, name, ref }) => (
              <Checkbox
                size="lg"
                label={t('addDriver.safetyCheck')}
                ref={ref}
                testId="add-driver-checkbox"
                align="start"
                onChange={onChange}
                checked={value}
                name={name}
              />
            )}
          />
        </form>
      </ContainerWithActionFooter>
    </Modal>
  );
};
