import React from 'react';

import cx from 'classnames';
import { Label, RadioGroup, Heading } from 'components/common';
import { GC } from 'containers/Routes/Routes.constants';
import { useAppMode } from 'hooks/useAppMode';
import { WorkType } from 'models/Report';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import styles from './WorkCard.module.scss';

interface WorkCardProps {
  startDate?: string;
  endDate?: string;
  control: Control;
  fieldName: string;
  defaults: {
    startDate?: string;
    endDate?: string;
    clearanceType?: WorkType | null;
  };
  driverName: string;
  isUser?: boolean;
  index?: number;
}

export const WorkCard: React.FC<WorkCardProps> = ({
  driverName,
  isUser,
  control,
  fieldName,
  defaults,
  index,
}) => {
  const { t } = useTranslation();
  const { appMode } = useAppMode();

  return (
    <div
      className={styles['work-card__card']}
      data-testid="work-card-gc-header"
    >
      {appMode === GC && (
        <div className={styles['work-card__row']}>
          <Heading size="xs" tag="h6" className={styles['work-card__name']}>
            {driverName} {isUser && '(You)'}
          </Heading>
        </div>
      )}

      <div className={styles['work-card__row']} data-testid="work-card">
        <Controller
          control={control}
          name={`${fieldName}.startDate`}
          defaultValue={defaults.startDate}
          render={({ onChange, value, name }) => {
            const isBeforeToday =
              value && new Date(value).getTime() > new Date().getTime();

            return (
              <label
                htmlFor={t('reviewWork.startDate')}
                className={styles['work-card__date-field__label']}
              >
                Start Date
                <input
                  type="datetime-local"
                  data-testid="work-card-start-date"
                  value={value}
                  name={name}
                  onChange={(e) => onChange(e.target.value)}
                  className={cx({
                    [`${styles['work-card__date-field__input']}`]: true,
                    [`${styles['work-card__date-field__input--error']}`]:
                      isBeforeToday,
                  })}
                />
                {isBeforeToday && (
                  <p
                    data-testid="work-card-error-start-date"
                    className={styles['work-card__date-field__text-error']}
                  >
                    The Start Date cannot be greater than current date and time
                    or End Date
                  </p>
                )}
              </label>
            );
          }}
        />
      </div>

      <div className={styles['work-card__row']}>
        <Controller
          control={control}
          name={`${fieldName}.endDate`}
          defaultValue={defaults.endDate}
          render={({ onChange, value, name }) => {
            const obj = control.getValues();
            let field: WorkCardProps;

            if (index !== undefined) {
              field = obj.additionalDrivers[index];
            } else {
              field = obj[fieldName];
            }

            const isBeforeStartDate =
              value &&
              field &&
              field.startDate &&
              new Date(value).getTime() < new Date(field.startDate).getTime();

            const isGreaterThanNow =
              new Date(value).getTime() > new Date().getTime();

            return (
              <label
                htmlFor={t('reviewWork.endDate')}
                className={styles['work-card__date-field__label']}
              >
                End Date
                <input
                  type="datetime-local"
                  data-testid="work-card-end-date"
                  value={value}
                  name={name}
                  onChange={(e) => onChange(e.target.value)}
                  className={cx({
                    [`${styles['work-card__date-field__input']}`]: true,
                    [`${styles['work-card__date-field__input--error']}`]:
                      isBeforeStartDate,
                  })}
                />
                {isBeforeStartDate && (
                  <p
                    data-testid="work-card-error-before-start-date"
                    className={styles['work-card__date-field__text-error']}
                  >
                    The End Date must be after the Start Date
                  </p>
                )}
                {isGreaterThanNow && (
                  <p
                    data-testid="work-card-error-after-current-date"
                    className={styles['work-card__date-field__text-error']}
                  >
                    The End Date must be before the current date
                  </p>
                )}
              </label>
            );
          }}
        />
      </div>

      <div className={styles['work-card__divider']} />
      <Label className={styles['work-card__label']} size="sm">
        {t('reviewWork.clearanceTypes.title')}
      </Label>
      <Controller
        control={control}
        name={`${fieldName}.clearanceType`}
        defaultValue={defaults.clearanceType}
        render={({ onChange, value, name }) => (
          <RadioGroup
            options={[
              {
                label: t('reviewWork.clearanceTypes.mechanical'),
                value: 'mechanical',
              },
              { label: t('reviewWork.clearanceTypes.manual'), value: 'manual' },
              { label: t('reviewWork.clearanceTypes.both'), value: 'mixed' },
            ]}
            orientation="vertical"
            name={name}
            value={value}
            onChange={(newValue) => onChange(newValue)}
          />
        )}
      />
    </div>
  );
};
