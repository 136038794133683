import React from 'react';

import { Heading, Modal, Text } from 'components/common';
import { useJobContext } from 'contexts/JobContext';
import { useTranslation } from 'react-i18next';

import styles from './SiteNotesModal.module.scss';

interface SiteNotesModalProps {
  closeModal: () => void;
}

export const SiteNotesModal: React.FC<SiteNotesModalProps> = ({
  closeModal,
}) => {
  const { t } = useTranslation();
  const { job } = useJobContext();

  return (
    <Modal
      title={t('siteNotes.title')}
      closeModal={closeModal}
      testId="site-notes-modal"
    >
      <div className={styles['site-notes']}>
        {job?.padlock_code && (
          <>
            <Heading>Padlock Code</Heading>
            <Text> {job?.padlock_code}</Text>
          </>
        )}

        {job?.padlock_code && job.notes && (
          <Heading className={styles['notes-heading']}>Notes</Heading>
        )}

        <Text>{job?.notes}</Text>
      </div>
    </Modal>
  );
};
