import { apiClient } from 'api/utils';
import { Job, ApiJob } from 'models/Job';

import DriverWorkApi from './DriverWorkApi.constants';
import { mapDriverWorkResponse } from './DriverWorkMap';

export const getDriverWorkAPI = async (workId: string): Promise<Job> => {
  const response: { data: ApiJob } = await apiClient.get(
    DriverWorkApi(workId).BY_WORK_ID,
  );
  return mapDriverWorkResponse(response.data);
};
