import React from 'react';

import { AlternativeMaps } from 'components/AlternativeMaps';
import { Modal, Tabs, Tab } from 'components/common';
import { SiteAreas } from 'components/SiteAreas';
import { useJobContext } from 'contexts/JobContext';
import { useTranslation } from 'react-i18next';

interface SiteMapModalProps {
  closeModal: () => void;
}

export const SiteMapModal: React.FC<SiteMapModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { job } = useJobContext();

  return (
    <Modal
      title={t('siteMap.title')}
      closeModal={closeModal}
      testId="site-map-modal"
    >
      {job?.alternativeMaps.length ? (
        <Tabs>
          <Tab title={t('siteMap.areas')}>
            <SiteAreas
              center={job?.siteLocation}
              polygons={job?.kmlMap}
              hazards={job?.hazards}
            />
          </Tab>
          <Tab title={t('siteMap.alternativeMap')}>
            <AlternativeMaps maps={job.alternativeMaps} />
          </Tab>
        </Tabs>
      ) : (
        <SiteAreas
          center={job?.siteLocation}
          polygons={job?.kmlMap}
          hazards={job?.hazards}
        />
      )}
    </Modal>
  );
};
