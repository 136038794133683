import * as Yup from 'yup';
import { ObjectSchema } from 'yup';

export const addDriverSchema = (): ObjectSchema =>
  Yup.object().shape(
    {
      safetyCheck: Yup.boolean().oneOf([true]),
      driver: Yup.object().when(['firstName', 'lastName'], {
        is: (firstName, lastName) => !firstName || !lastName,
        then: Yup.object().required('Must select a driver'),
        otherwise: Yup.object().nullable(),
      }),

      firstName: Yup.string().when(['lastName', 'driver'], {
        is: (lastName, driver) => lastName && !driver,
        then: Yup.string()
          .required('Must add a first name')
          .min(2, 'First Name should be at least 2 chartacters'),
      }),
      lastName: Yup.string().when(['firstName', 'driver'], {
        is: (firstName, driver) => firstName && !driver,
        then: Yup.string()
          .required('Must add a last name')
          .min(2, 'Last Name should be at least 2 chartacters'),
      }),
    },
    [
      ['firstName', 'lastName'],
      ['lastName', 'driver'],
      ['firstName', 'driver'],
    ],
  );
