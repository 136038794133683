import React from 'react';

import { useLocalStorage } from 'hooks/useLocalStorage';
import { useWorkId } from 'hooks/useWorkId';

export interface StepperContextProps {
  totalSteps: string[];
  currentStep: string;
  setCurrentStep: (value: string) => void;
}

export const StepperContextValues = (): StepperContextProps => {
  const { workId } = useWorkId();

  const [currentStep, setCurrentStep] = useLocalStorage<string>(
    `currentStep[${workId}]`,
  );

  return {
    totalSteps: [],
    currentStep,
    setCurrentStep,
  };
};

export const StepperContext = React.createContext<StepperContextProps>(
  {} as ReturnType<typeof StepperContextValues>,
);

export const useStepperContext = (): StepperContextProps =>
  React.useContext(StepperContext);

export const StepperProvider: React.FC = ({ children }) => {
  return (
    <StepperContext.Provider value={StepperContextValues()}>
      {children}
    </StepperContext.Provider>
  );
};
