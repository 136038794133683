import React, { useEffect, useState } from 'react';

import ExpandIcon from 'assets/icons/AddCircle.svg';
import { AddDriverModal } from 'components/AddDriverModal';
import { Button, Icon, Heading, Text } from 'components/common';
import { PageContent } from 'components/PageContent';
import { ContainerWithActionFooter } from 'containers/ContainerWithActionFooter';
import { LayoutWithStepper } from 'containers/LayoutWithStepper';
import { RouteNames } from 'containers/Routes';
import { GC } from 'containers/Routes/Routes.constants';
import { useAccountContext } from 'contexts/AccountContext';
import { useDriverListContext } from 'contexts/DriverListContext';
import { useReportContext } from 'contexts/ReportContext';
import { useStepperContext } from 'contexts/StepperContext';
import { format } from 'date-fns';
import { useAppMode } from 'hooks/useAppMode';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './WorkInProgressPage.module.scss';

export const WorkInProgressPage: React.FC = () => {
  const [showAddDriverModal, setShowAddDriverModal] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const { setCurrentStep } = useStepperContext();
  const { appMode } = useAppMode();
  const { account } = useAccountContext();
  const { loading, getNameFromDriver, driverList, getDrivers } =
    useDriverListContext();
  const { report, additionalDrivers, updateReport, updateAdditionalDriver } =
    useReportContext();

  useEffect(() => {
    if (!driverList && appMode === GC) {
      getDrivers();
    }
  }, [driverList, getDrivers, appMode]);

  useEffect(() => {
    setCurrentStep(RouteNames.WORK_IN_PROGRESS);
  }, [setCurrentStep]);

  const handleClick = () => {
    history.push(RouteNames.REVIEW_WORK);
  };

  const markUserAsFinished = () => {
    updateReport({
      end_date_time: new Date(),
    });
  };

  const markAdditionalAsFinished = (uid: string) => {
    updateAdditionalDriver(uid, {
      end_date_time: new Date(),
    });
  };

  return (
    <LayoutWithStepper>
      <ContainerWithActionFooter
        onClick={handleClick}
        label={t('workInProgress.action')}
        actionTestId="work-in-progress-submit"
      >
        <PageContent
          step={t('workInProgress.step', {
            step: appMode === GC ? '3' : '4',
          })}
          heading={t('workInProgress.title')}
          body={t('workInProgress.body')}
        />

        {!loading && appMode === GC && (
          <>
            <div className={styles['drivers-list']}>
              <DriverItem
                name={account?.name}
                finishedTime={report?.end_date_time}
                activeUser
                markAsFinished={() => markUserAsFinished()}
              />

              {additionalDrivers.map((driver) => (
                <DriverItem
                  key={driver?.uid}
                  name={getNameFromDriver(driver)}
                  finishedTime={driver.end_date_time}
                  markAsFinished={() =>
                    driver.uid && markAdditionalAsFinished(driver.uid)
                  }
                />
              ))}
            </div>

            <button
              onClick={() => setShowAddDriverModal(true)}
              className={styles['add-button']}
              data-testid="add-button"
            >
              <Icon icon={ExpandIcon} fillColor="accent" />
              Add driver
            </button>
          </>
        )}
      </ContainerWithActionFooter>

      {showAddDriverModal && (
        <AddDriverModal closeModal={() => setShowAddDriverModal(false)} />
      )}
    </LayoutWithStepper>
  );
};

interface DriverItemProps {
  name?: string;
  finishedTime?: Date | null;
  activeUser?: boolean;
  // TODO this was added when moving away from CRA to Webpack 5 GCAT-379. Redo this as a type along with sanitising the types in this app
  // eslint-disable-next-line @typescript-eslint/ban-types
  markAsFinished: Function;
}

const DriverItem: React.FC<DriverItemProps> = ({
  name,
  finishedTime,
  activeUser,
  markAsFinished,
}) => {
  return (
    <div className={styles['driver-item']} data-testid={`driver-item`}>
      <Heading size="xs">
        {name} {activeUser && '(You)'}
      </Heading>

      {finishedTime ? (
        <Text size="sm" className="driver-finished-time">
          {format(new Date(finishedTime), 'd MMM HH:mm')}
        </Text>
      ) : (
        <Button
          appearance="outline"
          size="sm"
          onClick={() => markAsFinished()}
          testId="driver-item-finished"
        >
          Finished
        </Button>
      )}
    </div>
  );
};
