import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers';
import { postAccessIssue } from 'api/AccessIssueAPI';
import {
  ImageUpload,
  ImageUploadItem,
  InputText,
  Label,
  RadioGroup,
} from 'components/common';
import { PageContent } from 'components/PageContent';
import { ContainerWithActionFooter } from 'containers/ContainerWithActionFooter';
import { ACCESS, ISSUE_REPORTED } from 'containers/Routes/Routes.constants';
import { useJobContext } from 'contexts/JobContext';
import { useWorkId } from 'hooks/useWorkId';
import { serialize } from 'object-to-formdata';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './AccessIssuesPage.module.scss';
import { accessIssuesSchema } from './accessIssuesSchema';

interface SubmitFormData {
  reason: string;
  message: string;
  media: ImageUploadItem[];
}

const accessIssues = [
  'keyFailed',
  'codeFailed',
  'gateLocked',
  'noSecurity',
  'denied',
  'roadClosed',
  'other',
];

export const AccessIssuesPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { workId } = useWorkId();
  const [sending, setSending] = useState(false);
  const { job } = useJobContext();

  const { register, control, handleSubmit, formState, watch } = useForm({
    resolver: yupResolver(accessIssuesSchema(t, job?.disablePhoto)),
    mode: 'onChange',
    defaultValues: {
      reason: '',
      message: '',
      media: [],
    },
  });
  const { reason } = watch();
  const showOtherInput = reason === t('accessIssues.reasons.other');

  const getAccessIssuesValues = () =>
    accessIssues.map((value) => ({
      value: t(`accessIssues.reasons.${value}`),
      label: t(`accessIssues.reasons.${value}`),
    }));

  const onFormSubmit = async (data: SubmitFormData) => {
    try {
      setSending(true);
      const dataWithFile = {
        ...data,
        media: data.media?.map((media) => media.file),
      };
      const formData = serialize(dataWithFile, { indices: true });

      await postAccessIssue({ formData, workId });

      history.push(ISSUE_REPORTED);
    } catch (error) {
      console.error(error);
      alert('Something went wrong. Please try again');
      setSending(false);
    }
  };

  const onFormCancel = () => {
    history.push(ACCESS);
  };

  return (
    <form
      id="access-issues-form"
      onSubmit={handleSubmit(onFormSubmit)}
      className={styles['access-issues']}
      data-testid="access-issues-form"
    >
      <ContainerWithActionFooter
        form="access-issues-form"
        label={t('accessIssues.action')}
        disabled={!formState.isValid || sending}
        actionTestId="access-issues-submit"
        onCancel={onFormCancel}
      >
        <PageContent
          heading={t('accessIssues.title')}
          body={t('accessIssues.body')}
        />

        <Controller
          control={control}
          name="reason"
          defaultValue={null}
          render={({ onChange, value, name }) => (
            <RadioGroup
              labelBy="accessIssues"
              options={getAccessIssuesValues()}
              orientation="vertical"
              name={name}
              value={value}
              onChange={(newValue) => onChange(newValue)}
            />
          )}
        />

        {showOtherInput && (
          <InputText
            variant="minimal"
            name="message"
            placeholder={t('accessIssues.otherPlaceholder')}
            testId="access-issues-other-input"
            ref={register}
          />
        )}

        {!job?.disablePhoto && (
          <>
            <Label className={styles['access-issues__label']} size="md">
              {t('accessIssues.photosText')}
            </Label>
            <Controller
              control={control}
              name="media"
              render={({ onChange, value }) => (
                <ImageUpload
                  imageList={value}
                  setImageList={onChange}
                  multiple
                />
              )}
            />
          </>
        )}
      </ContainerWithActionFooter>
    </form>
  );
};
