import React, { useEffect, useState } from 'react';

import { ImageUpload, ImageUploadItem } from 'components/common';
import { PageContent } from 'components/PageContent';
import { ContainerWithActionFooter } from 'containers/ContainerWithActionFooter';
import { LayoutWithStepper } from 'containers/LayoutWithStepper';
import { RouteNames } from 'containers/Routes';
import { GC } from 'containers/Routes/Routes.constants';
import { useJobContext } from 'contexts/JobContext';
import { useReportContext } from 'contexts/ReportContext';
import { useStepperContext } from 'contexts/StepperContext';
import { useAppMode } from 'hooks/useAppMode';
import { ValidationErrors } from 'models/Report';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './AfterPhotosPage.module.scss';

export const AfterPhotosPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setCurrentStep } = useStepperContext();
  const { submitWorkReport } = useReportContext();
  const { appMode } = useAppMode();
  const { job } = useJobContext();

  const [imageList, setImageList] = useState<ImageUploadItem[]>([]);
  const [isSending, setIsSending] = useState(false);
  const [isDisabledPhotos, setIsDisabledPhotos] = useState(false);

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setIsSending(true);

      submitWorkReport(imageList);

      history.push(RouteNames.SUBMITTED);
    } catch (error) {
      if (error.response.data?.validationErrors) {
        const validationErrors: ValidationErrors[] =
          error.response.data?.validationErrors;

        validationErrors.forEach((vError) => alert(vError.message));
      } else {
        console.error(error);
        alert('Something went wrong. Please try again');
      }

      setIsSending(false);
    }
  };

  useEffect(() => {
    const isSiteDisabledPhoto = job?.disablePhoto || false;

    setCurrentStep(RouteNames.AFTER_PHOTOS);
    setIsDisabledPhotos(isSiteDisabledPhoto);
  }, [imageList.length, job?.disablePhoto, setCurrentStep]);

  return (
    <LayoutWithStepper>
      <ContainerWithActionFooter
        form="after-photos-form"
        label={t('afterPhotos.action')}
        disabled={(!isDisabledPhotos && imageList.length < 1) || isSending}
        actionTestId="after-photos-submit"
      >
        <form
          id="after-photos-form"
          onSubmit={onFormSubmit}
          data-testid="after-photos-form"
        >
          <PageContent
            step={t('afterPhotos.step', { step: appMode === GC ? '5' : '6' })}
            heading={t('afterPhotos.title')}
            body={t('afterPhotos.body')}
            isDisabled={isDisabledPhotos}
          />
          <ImageUpload
            imageList={imageList}
            setImageList={setImageList}
            isDisabled={isDisabledPhotos}
            multiple
          />
          {isDisabledPhotos && (
            <span className={styles['after-photos-disabled-photos']}>
              {t('afterPhotos.disabledPhotos', {
                button: t('afterPhotos.action'),
              })}
            </span>
          )}
        </form>
      </ContainerWithActionFooter>
    </LayoutWithStepper>
  );
};
