import i18n from 'i18next';
import * as Yup from 'yup';

const workSchema = () =>
  Yup.object().shape(
    {
      startDate: Yup.date()
        .when('endDate', (endDate: Date, schema: Yup.DateSchema) =>
          endDate
            ? schema.max(endDate, i18n.t('reviewWork.errors.startDate'))
            : schema,
        )
        .required(),
      endDate: Yup.date()
        .when('startDate', (startDate: Date, schema: Yup.DateSchema) =>
          startDate
            ? schema
                .min(startDate, i18n.t('reviewWork.errors.endDate'))
                .max(new Date())
            : schema,
        )
        .required(),
      clearanceType: Yup.string().when(['startDate', 'endDate'], {
        is: (exist) => exist,
        then: Yup.string().required(),
      }),
    },
    [
      ['startDate', 'endDate'],
      ['endDate', 'startDate'],
    ],
  );

export const reviewWorkPageSchema = () =>
  Yup.lazy(() =>
    Yup.object().shape({
      report: workSchema(),
      additionalDrivers: Yup.array().of(workSchema()),
    }),
  );
