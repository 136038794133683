import React, { useState } from 'react';

import { TabItem } from './TabItem';
import styles from './Tabs.module.scss';

export interface TabProps {
  title: string;
}
export interface TabsProps {
  children: React.ReactElement[];
}

export const Tab: React.FC<TabProps> = ({ children }) => (
  <div className={styles['tabs__tab']}>{children}</div>
);

export const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const titles = React.Children.map(
    children,
    (child: React.ReactElement<TabProps>) => child?.props.title,
  );

  return (
    <div className={styles['tabs']}>
      <div className={styles['tabs__header']}>
        {(titles as unknown as string[])?.map((title, i) => {
          return (
            <TabItem
              key={title}
              isActive={i === activeTab}
              onClick={() => setActiveTab(i)}
            >
              {title}
            </TabItem>
          );
        })}
      </div>
      {React.Children.map(children, (child: React.ReactNode, i) =>
        i === activeTab ? child : null,
      )}
    </div>
  );
};
