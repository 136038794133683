import React from 'react';

import CheckCircle from 'assets/icons/CheckCircle.svg';
import { Text } from 'components/common';
import { InfoMessage } from 'components/InfoMessage';
import { ContainerWithActionFooter } from 'containers/ContainerWithActionFooter';
import { CLOSE, GC } from 'containers/Routes/Routes.constants';
import { useAppMode } from 'hooks/useAppMode';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './IssueReportedPage.module.scss';

export const IssueReportedPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { appMode } = useAppMode();

  const onCloseClicked = () => {
    if (window.Android?.close) {
      window.Android.close();
    } else {
      history.push(CLOSE);
    }
  };

  return (
    <ContainerWithActionFooter
      label={t('issueReported.button')}
      actionTestId="issue-reported-close"
      onClick={onCloseClicked}
      hideButton={appMode !== GC}
    >
      <div className={styles['issue-reported']}>
        <InfoMessage
          icon={CheckCircle}
          iconStrokeColor="accent"
          size="xxl"
          title={t('issueReported.title')}
          body={t('issueReported.body')}
        />
        <a
          href={`tel:${t('issueReported.phone')}`}
          className={styles['issue-reported__phone']}
        >
          <Text size="lg">{t('issueReported.phone')}</Text>
        </a>
      </div>
    </ContainerWithActionFooter>
  );
};
