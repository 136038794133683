import Axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

export const apiClient = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

interface AxiosConfigHeaders extends AxiosRequestConfig {
  headers: AxiosRequestHeaders;
}

apiClient.interceptors.request.use((axiosConfig) => {
  const token = window.localStorage.getItem('token');
  const config = axiosConfig as AxiosConfigHeaders;

  if (token != null) {
    config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }

  return config;
});
