import { apiClient } from 'api/utils';
import { ShareToken } from 'models/ShareWork';

import ShareWorkAPI from './ShareWorkAPI.constants';

export const getShareWorkToken = async (
  workId: string,
): Promise<ShareToken> => {
  const res = await apiClient.post(ShareWorkAPI(workId).SHARE_WORK);

  return res.data;
};
