import React, { useEffect, useState } from 'react';

import { postInitialReport } from 'api/InitialReportAPI';
import { ImageUpload, ImageUploadItem } from 'components/common';
import { PageContent } from 'components/PageContent';
import { ContainerWithActionFooter } from 'containers/ContainerWithActionFooter';
import { LayoutWithStepper } from 'containers/LayoutWithStepper';
import { RouteNames } from 'containers/Routes';
import { GC } from 'containers/Routes/Routes.constants';
import { useJobContext } from 'contexts/JobContext';
import { useStepperContext } from 'contexts/StepperContext';
import { useAppMode } from 'hooks/useAppMode';
import { useWorkId } from 'hooks/useWorkId';
import { ValidationErrors } from 'models/Report';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './BeforePhotosPage.module.scss';

export const BeforePhotosPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setCurrentStep } = useStepperContext();
  const { appMode } = useAppMode();
  const { workId } = useWorkId();
  const { job } = useJobContext();

  const [imageList, setImageList] = useState<ImageUploadItem[]>([]);
  const [isSending, setIsSending] = useState(false);
  const [isDisabledPhotos, setIsDisabledPhotos] = useState(false);

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setIsSending(true);

      const formData = new FormData();

      imageList.forEach((image, index) => {
        formData.append(`media[${index}]`, image.file);
      });

      formData.append('completion_step', '2');

      await postInitialReport({ formData, workId });

      history.push(RouteNames.WORK_IN_PROGRESS);
    } catch (error) {
      if (error.response.data?.validationErrors) {
        const validationErrors: ValidationErrors[] =
          error.response.data?.validationErrors;

        validationErrors.forEach((vError) => alert(vError.message));
      } else {
        console.error(error);
        alert('Something went wrong. Please try again');
      }

      setIsSending(false);
    }
  };

  useEffect(() => {
    const isSiteDisabledPhoto = job?.disablePhoto || false;

    setCurrentStep(RouteNames.BEFORE_PHOTOS);
    setIsDisabledPhotos(isSiteDisabledPhoto);
  }, [job?.disablePhoto, setCurrentStep, setIsDisabledPhotos]);

  return (
    <LayoutWithStepper>
      <ContainerWithActionFooter
        form="before-photos-form"
        label={t('beforePhotos.action')}
        disabled={(!isDisabledPhotos && imageList.length < 1) || isSending}
        actionTestId="before-photos-submit"
      >
        <form
          id="before-photos-form"
          onSubmit={onFormSubmit}
          data-testid="before-photos-form"
        >
          <PageContent
            step={t('beforePhotos.step', {
              step: appMode === GC ? '2' : '3',
            })}
            heading={t('beforePhotos.title')}
            body={t('beforePhotos.body')}
            isDisabled={isDisabledPhotos}
          />
          <ImageUpload
            imageList={imageList}
            setImageList={setImageList}
            isDisabled={isDisabledPhotos}
            multiple
          />
          {isDisabledPhotos && (
            <span className={styles['before-photos-disabled-photos']}>
              {t('beforePhotos.disabledPhotos', {
                button: t('beforePhotos.action'),
              })}
            </span>
          )}
        </form>
      </ContainerWithActionFooter>
    </LayoutWithStepper>
  );
};
