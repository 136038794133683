import React from 'react';

import { ActionFooter } from 'components/ActionFooter';

import styles from './ContainerWithActionFooter.module.scss';

interface ContainerWithActionFooterProps {
  label: string;
  form?: string;
  actionTestId?: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  onCancel?: () => void;
  hideButton?: boolean;
}

export const ContainerWithActionFooter: React.FC<
  ContainerWithActionFooterProps
> = ({
  form,
  actionTestId,
  label,
  disabled,
  onClick,
  onCancel,
  hideButton,
  children,
}) => {
  return (
    <div className={styles['layout-action']}>
      <div className={styles['layout-action__content']}>{children}</div>
      {!hideButton && (
        <ActionFooter
          form={form}
          testId={actionTestId}
          label={label}
          disabled={disabled}
          onClick={onClick}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};
