import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';

import { App } from 'containers/App';
import ReactDOM from 'react-dom';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
