import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers';
import { InputText } from 'components/common';
import { PageContent } from 'components/PageContent';
import { ContainerWithActionFooter } from 'containers/ContainerWithActionFooter';
import { LayoutWithStepper } from 'containers/LayoutWithStepper';
import { RouteNames } from 'containers/Routes';
import { DriverDetailsProps, useAccountContext } from 'contexts/AccountContext';
import { useStepperContext } from 'contexts/StepperContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useWorkId } from 'hooks/useWorkId';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './DetailsPage.module.scss';
import { detailsPageSchema } from './detailsPageSchema';

interface DetailsLocalStorage {
  firstName: string;
  lastName: string;
}

export const DetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setCurrentStep } = useStepperContext();
  const { updateDriver, updateLoading } = useAccountContext();
  const { workId } = useWorkId();
  const [details, setDetails] = useLocalStorage<DetailsLocalStorage>(
    `details[${workId}]`,
  );

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(detailsPageSchema()),
    mode: 'onChange',
    defaultValues: {
      firstName: details?.firstName || null,
      lastName: details?.lastName || null,
    },
  });

  const onFormSubmit = async (data: DriverDetailsProps) => {
    try {
      setDetails(data);
      await updateDriver(data);

      history.push(RouteNames.ACCESS);
    } catch (error) {}
  };

  useEffect(() => {
    setCurrentStep(RouteNames.DETAILS);
  }, [setCurrentStep]);

  return (
    <LayoutWithStepper>
      <ContainerWithActionFooter
        form="details-form"
        actionTestId="details-page-submit"
        label={t('details.action')}
        disabled={!formState.isValid || updateLoading}
      >
        <form
          id="details-form"
          onSubmit={handleSubmit(onFormSubmit)}
          className={styles['details-page']}
          data-testid={'details-page'}
        >
          <PageContent
            step={t('details.step', { step: '1' })}
            heading={t('details.title')}
          />
          <div className={styles['details-page__input']}>
            <InputText
              label={t('details.firstName')}
              name="firstName"
              ref={register}
            />
          </div>
          <div className={styles['details-page__input']}>
            <InputText
              label={t('details.secondName')}
              name="lastName"
              ref={register}
            />
          </div>
        </form>
      </ContainerWithActionFooter>
    </LayoutWithStepper>
  );
};
