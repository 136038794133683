import React from 'react';

import cx from 'classnames';
import { Heading, Label, Text } from 'components/common';

import styles from './PageContent.module.scss';

interface PageContentProps {
  step?: string;
  heading?: string;
  body?: string;
  isDisabled?: boolean;
}

export const PageContent: React.FC<PageContentProps> = ({
  step,
  heading,
  body,
  isDisabled,
}) => {
  return (
    <div className={styles['page-content']}>
      <Label className={styles['page-content__label']}>{step}</Label>
      <div
        className={cx(styles['page-content__inner-container'], {
          [styles['page-content__inner-container--disabled']]: isDisabled,
        })}
      >
        <Heading className={styles['page-content__heading']}>{heading}</Heading>
        <Text size="lg" className={styles['page-content__body']}>
          {body}
        </Text>
      </div>
    </div>
  );
};
