import { useLocalStorage } from 'hooks/useLocalStorage';

type AppModeValue = 'GC' | 'TEMP';

interface AppModeTypes {
  appMode: AppModeValue;
  setAppMode: (value: AppModeValue) => void;
}

export const useAppMode = (): AppModeTypes => {
  const [appMode, setAppMode] = useLocalStorage<AppModeValue>('mode');

  return { appMode, setAppMode };
};
