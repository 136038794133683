import React from 'react';

import { Stepper } from 'components/common';
import { useJobContext } from 'contexts/JobContext';
import { useStepperContext } from 'contexts/StepperContext';
import { useAppMode } from 'hooks/useAppMode';

import styles from './LayoutWithStepper.module.scss';
import {
  ACCESS,
  AFTER_PHOTOS,
  BEFORE_PHOTOS,
  DETAILS,
  GC,
  REVIEW_WORK,
  WORK_IN_PROGRESS,
} from '../Routes/Routes.constants';

export const LayoutWithStepper: React.FC = ({ children }) => {
  const { job } = useJobContext();
  const { currentStep } = useStepperContext();

  const { appMode } = useAppMode();

  const stepsTemp = [
    DETAILS,
    ACCESS,
    BEFORE_PHOTOS,
    WORK_IN_PROGRESS,
    REVIEW_WORK,
    AFTER_PHOTOS,
  ];

  const stepsGC = [
    ACCESS,
    BEFORE_PHOTOS,
    WORK_IN_PROGRESS,
    REVIEW_WORK,
    AFTER_PHOTOS,
  ];

  return (
    <div className={styles['stepper-container']}>
      <Stepper
        steps={appMode === GC ? stepsGC : stepsTemp}
        currentStep={currentStep}
        title={job?.name}
        subtitle={job?.street}
        showNotes={!!job?.notes}
      />
      {children}
    </div>
  );
};
