import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers';
import GCLogo from 'assets/GCLogo.svg';
import { Heading, Text, Checkbox, Label } from 'components/common';
import { ContainerWithActionFooter } from 'containers/ContainerWithActionFooter';
import { RouteNames } from 'containers/Routes';
import { useJobContext } from 'contexts/JobContext';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './SharedPage.module.scss';

import { sharedPageSchema } from '.';

export const SharedPage: React.FC = () => {
  const [checksCompleted, setChecksCompleted] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { job } = useJobContext();
  const { name, street, customerName } = job || {};

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(sharedPageSchema()),
    mode: 'onChange',
  });

  const handleCheckboxChange = () => {
    setChecksCompleted(!checksCompleted);
  };

  const onFormSubmit = () => {
    history.push(RouteNames.DETAILS);
  };

  return (
    <ContainerWithActionFooter
      form="shared-page-form"
      actionTestId="shared-page__action"
      label={t('shared.action')}
      disabled={!checksCompleted}
      //disabled={!formState.isValid || !formState.isDirty}
    >
      <form
        id="shared-page-form"
        data-testid="shared-page"
        onSubmit={handleSubmit(onFormSubmit)}
        className={styles['shared-page']}
      >
        <GCLogo />

        <Heading>{t('shared.title')}</Heading>

        <Text size="lg" className={styles['shared-page__text']}>
          {t('shared.body')}
        </Text>

        <div className={styles['shared-page__details']}>
          <Label size="lg">{name}</Label>
          <Text size="sm">{street}</Text>
          <Text size="sm" className={styles['shared-page__details--light']}>
            {customerName} {/*  • {customerType} */}
          </Text>
          <div className={styles['shared-page__divider']}></div>
          <Text>{t('shared.jobType')}</Text>
        </div>

        <Checkbox
          size="lg"
          checked={checksCompleted}
          onChange={handleCheckboxChange}
          label={t('shared.safety')}
          ref={register}
          name="safetyCheck"
          testId="shared-page__checkbox"
        />
      </form>
    </ContainerWithActionFooter>
  );
};
