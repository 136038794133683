import { useLocalStorage } from 'hooks/useLocalStorage';

interface WorkIdTypes {
  workId: string;
  setWorkId: (value: string) => void;
}

export const useWorkId = (): WorkIdTypes => {
  const [workId, setWorkId] = useLocalStorage<string>('workId');

  return { workId, setWorkId };
};
