import React from 'react';

import styles from './IconButton.module.scss';
import { Icon, Text, IconColorVariant } from '..';

export interface IconButtonProps {
  label?: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  iconFillColor?: IconColorVariant;
  iconStrokeColor?: IconColorVariant;
  iconSize?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  labelColor?: IconColorVariant;
  labelSize?: 'sm' | 'md' | 'lg';
  testId?: string;
  onClick?: () => void;
}

export const IconButton: React.FC<IconButtonProps> = ({
  label,
  icon,
  iconFillColor,
  iconStrokeColor,
  iconSize = 'sm',
  labelColor,
  labelSize = 'sm',
  testId,
  onClick,
}) => {
  return (
    <button onClick={onClick} className={styles['icon-button']}>
      <Icon
        icon={icon}
        size={iconSize}
        fillColor={iconFillColor}
        strokeColor={iconStrokeColor}
        testId={testId}
      />
      {label && (
        <Text
          size={labelSize}
          className={labelColor && styles[`icon-button__label--${labelColor}`]}
        >
          {label}
        </Text>
      )}
    </button>
  );
};
