import React from 'react';

import System from 'assets/icons/System.svg';
import cx from 'classnames';
import { Label, Icon } from 'components/common';
import { Input } from 'reakit/Input';

import styles from './InputText.module.scss';

type InputTextProps = {
  name: string;
  label?: string;
  caption?: string;
  className?: string;
  floating?: boolean;
  disabled?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  readonly?: boolean;
  required?: boolean;
  placeholder?: string;
  radius?: 'rounded' | 'pill';
  size?: 'sm' | 'md' | 'lg';
  testId?: string;
  truncate?: boolean;
  variant?: 'outline' | 'accent' | 'positive' | 'negative' | 'minimal';
  validationError?: string;
};

const InputTextRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  InputTextProps
> = (
  {
    label,
    name,
    caption,
    className,
    disabled,
    icon,
    floating,
    placeholder,
    radius = 'rounded',
    readonly,
    required,
    size = 'md',
    testId,
    truncate = false,
    variant = 'accent',
    validationError,
    ...props
  },
  ref,
) => {
  const ariaDescribedBy = !!caption || !!validationError;
  const showLabel = label && (
    <Label
      inputId={`input__text-${name}`}
      size={size}
      className={styles['input__text-label']}
    >
      {label}
    </Label>
  );
  let textClasses = cx(`${styles['input__text-input']}`, {
    [`${styles[`input__text-input--${radius}`]}`]: radius,
    [`${styles[`input__text-input--${variant}`]}`]: variant,
    [`${className}`]: className,
    'text-truncate': truncate,
  });
  let showValidationIcon;

  if (variant === 'negative') {
    textClasses = textClasses + ' text-truncate';
    showValidationIcon = (
      <Icon
        className="validation"
        icon={System}
        size={size}
        testId={!!testId ? `icon` + testId : ''}
      />
    );
  }

  return (
    <div
      className={`${styles['input__text-group']} ${
        styles[`input__text-group--${size}`]
      } ${variant ? styles[`input__text-group--${variant}`] : ''} ${
        floating ? styles[`input__text-group--floating`] : ''
      }${icon ? styles[`input__text-group--icon`] : ''}`}
      data-testid={!!testId ? `group-${name}` : null}
    >
      {!floating && showLabel}
      <div className={styles['input__text-container']}>
        <Input
          type="text"
          id={`input__text-${name}`}
          name={name}
          className={textClasses}
          data-testid={!!testId ? testId : null}
          disabled={disabled}
          placeholder={placeholder}
          readOnly={readonly}
          required={required}
          ref={ref}
          {...(required && { 'aria-required': true })}
          {...(validationError && { 'aria-invalid': true })}
          {...(ariaDescribedBy && {
            'aria-describedby': `${!!validationError ? `error-${name} ` : ''}${
              !!caption ? `caption-${name}` : ''
            }`,
          })}
          {...props}
        />
        {!!icon && (
          <Icon
            className="prefix"
            icon={icon}
            size={size}
            testId={!!testId ? `icon` + testId : ''}
          />
        )}
        {!!validationError && showValidationIcon}
        {!!floating && showLabel}
      </div>
      {!!validationError && (
        <span
          id={`error-${name}`}
          className={styles['input__text-validation']}
          data-testid={!!testId ? `error-${name}` : null}
          role="alert"
        >
          {validationError}
        </span>
      )}
      {!!caption && (
        <span
          id={`caption-${name}`}
          className={styles['input__text-caption']}
          data-testid={!!testId ? `caption-${name}` : null}
        >
          {caption}
        </span>
      )}
    </div>
  );
};

export const InputText = React.forwardRef(InputTextRef);
