import React, { useEffect, useState } from 'react';

import { Spinner } from 'components/common';
import { useAccountContext } from 'contexts/AccountContext';
import { useJobContext } from 'contexts/JobContext';
import { useStepperContext } from 'contexts/StepperContext';
import { useAppMode } from 'hooks/useAppMode';
import { useWorkId } from 'hooks/useWorkId';
import {
  AccessIssuesPage,
  AfterPhotosPage,
  BeforePhotosPage,
  DetailsPage,
  ExpiredPage,
  SharedPage,
  IssueReportedPage,
  JobSubmittedPage,
  ReviewWorkPage,
  SiteAccessPage,
  WorkInProgressPage,
  ClosePage,
} from 'pages';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import {
  ACCESS,
  ACCESS_ISSUES,
  AFTER_PHOTOS,
  BEFORE_PHOTOS,
  CLOSE,
  DETAILS,
  GC,
  ISSUE_REPORTED,
  REVIEW_WORK,
  SHARED,
  SUBMITTED,
  TEMP,
  WORK_IN_PROGRESS,
} from './Routes.constants';

export const Routes: React.FC = () => {
  const { job, getJob } = useJobContext();
  const { account, getAccount } = useAccountContext();
  const { appMode, setAppMode } = useAppMode();
  const { workId } = useWorkId();

  const [expired, setExpired] = useState(false);

  useEffect(() => {
    if (!job) {
      getJob(workId).catch(() => {
        setExpired(true);
      });
    }
  }, [job, getJob, workId]);

  useEffect(() => {
    if (account) {
      if (account.role === 'driver') {
        setAppMode(GC);
      } else {
        setAppMode(TEMP);
      }
    } else {
      getAccount();
    }
  }, [account, appMode, getAccount, setAppMode]);

  if (expired) {
    return <ExpiredPage />;
  }

  if (job && account)
    return (
      <Switch>
        <Route path="/" exact>
          <EntryPoint />
        </Route>
        <Route path={SHARED} exact>
          <SharedPage />
        </Route>
        <Route path={ACCESS}>
          <SiteAccessPage />
        </Route>
        <Route path={ACCESS_ISSUES}>
          <AccessIssuesPage />
        </Route>
        <Route path={AFTER_PHOTOS}>
          <AfterPhotosPage />
        </Route>
        <Route path={BEFORE_PHOTOS}>
          <BeforePhotosPage />
        </Route>
        <Route path={DETAILS}>
          <DetailsPage />
        </Route>
        <Route path={ISSUE_REPORTED}>
          <IssueReportedPage />
        </Route>
        <Route path={REVIEW_WORK}>
          <ReviewWorkPage />
        </Route>
        <Route path={SUBMITTED}>
          <JobSubmittedPage />
        </Route>
        <Route path={WORK_IN_PROGRESS}>
          <WorkInProgressPage />
        </Route>
        <Route path={CLOSE}>
          <ClosePage />
        </Route>
      </Switch>
    );

  return <Spinner />;
};

const EntryPoint: React.FC = () => {
  const location = useLocation();
  const { currentStep } = useStepperContext();
  const { appMode } = useAppMode();

  if (currentStep && currentStep !== location.pathname) {
    return <Redirect to={currentStep} />;
  } else {
    switch (appMode) {
      case TEMP:
        return <Redirect to={SHARED} />;

      case GC:
        return <Redirect to={ACCESS} />;

      default:
        return null;
    }
  }
};
