import React, { useState } from 'react';

import CrossCircleIcon from 'assets/icons/CrossCircle.svg';
import DropDownIcon from 'assets/icons/DropDown.svg';
import cx from 'classnames';
import { Icon } from 'components/common/Icon';
import { Label } from 'components/common/Label';
import { useCombobox } from 'downshift';
import { useTranslation } from 'react-i18next';

import styles from './ComboBox.module.scss';

export interface SelectOption {
  label: string;
  value: string | number;
}

export interface ComboBoxProps {
  label?: string;
  id?: string;
  value?: SelectOption | null;
  placeholder?: string;
  disabled?: boolean;
  items: SelectOption[];
  onChange: (newValue?: SelectOption | null) => void;
  testId?: string;
  emptyMessage?: string;
  showReset?: boolean;
}

export const ComboBox: React.FC<ComboBoxProps> = ({
  label,
  id,
  value,
  placeholder,
  disabled = false,
  items,
  onChange,
  testId,
  emptyMessage,
  showReset,
}) => {
  const { t } = useTranslation();
  const [inputItems, setInputItems] = useState<SelectOption[]>(items);

  const {
    isOpen,
    inputValue,
    reset,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
  } = useCombobox({
    items: inputItems,
    itemToString: (item) => (item ? item.label : ''),
    initialSelectedItem: value,
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem),
    onInputValueChange: ({ inputValue, type }) =>
      type === '__input_change__'
        ? setInputItems(
            items.filter((item) =>
              item.label
                .toLowerCase()
                .includes(inputValue?.toLowerCase() || ''),
            ),
          )
        : setInputItems(items),
  });

  const getOptionStyles = (index: number) =>
    cx(`${styles['combobox__option']}`, {
      [styles['highlight']]: highlightedIndex === index,
    });

  const getMenuStyles = () =>
    cx(styles['combobox__menu'], { [styles['open']]: isOpen });

  return (
    <div
      className={cx(`${styles['combobox']}`, {
        [`${styles[`combobox--disabled`]}`]: disabled,
      })}
      data-testid={testId}
    >
      <Label
        inputId={id}
        testId={`${testId}-label`}
        className={styles['combobox__label']}
      >
        {label}
      </Label>
      <div className={styles['combobox__input-container']}>
        <div
          disabled
          className={styles['combobox__input']}
          {...getComboboxProps()}
        >
          <input
            id={id}
            className={styles['combobox__input-field']}
            data-testid={`${testId}-input`}
            placeholder={value?.label || placeholder}
            disabled={disabled}
            {...getInputProps({
              value: inputValue ?? (value?.label || ''),
              onBlur: () => value && setInputValue(value.label),
            })}
          />
          {showReset && (
            <button
              className={styles['combobox__control']}
              id="button"
              type="button"
              disabled={disabled}
              onClick={reset}
            >
              <Icon
                className={styles['combobox__icon']}
                icon={CrossCircleIcon}
                size="xs"
                fillColor="primary"
              />
            </button>
          )}
          <button
            className={styles['combobox__control']}
            id="button"
            type="button"
            disabled={disabled}
            data-testid={`${testId}-button`}
            {...getToggleButtonProps()}
          >
            <Icon
              className={styles['combobox__icon']}
              icon={DropDownIcon}
              size="xs"
              strokeColor="primary"
            />
          </button>
        </div>

        <ul className={getMenuStyles()} {...getMenuProps()}>
          {isOpen &&
            (inputItems.length > 0 ? (
              inputItems.map((item, index) => (
                <li
                  className={getOptionStyles(index)}
                  key={item.value}
                  {...getItemProps({ item, index })}
                  data-testid={`${testId}-list-item`}
                >
                  {item.label}
                </li>
              ))
            ) : (
              <li
                className={styles['combobox__option--empty']}
                data-testid={`${testId}-empty-option`}
              >
                {emptyMessage ?? t('common.error.noItems')}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
