import { apiClient } from 'api/utils';

import InitialReportAPI from './InitialReportAPI.constants';

interface InitialReportProps {
  formData: FormData;
  workId: string;
}

export const postInitialReport = async ({
  formData,
  workId,
}: InitialReportProps): Promise<void> => {
  return apiClient.post(InitialReportAPI(workId).INITIAL_REPORT, formData);
};
