/**
 * @name generateID
 * @return {string} randomised string as `unique` id reference
 * @desc not a true crypto `uuid` but, acceptable for html id generation
 */
export const generateID = (): string => {
  return (
    new Date().getTime().toString(16) +
    Math.floor(1e8 * Math.random()).toString(16)
  ).substring(0, 17);
};
