import React, { useCallback, useState } from 'react';

import { getDriverList } from 'api/DriverList';
import { DriverList } from 'models/DriverList';
import { AdditionalDriver } from 'models/Report';

export interface DriverListContextProps {
  driverList: DriverList | null;
  loading: boolean;
  getDrivers: () => void;
  getNameFromDriver: (driver: AdditionalDriver) => string;
}

export const DriverListContextDefaults: DriverListContextProps = {
  driverList: null,
  loading: false,
  getDrivers: () => null,
  getNameFromDriver: () => '',
};

export const DriverListContext = React.createContext<DriverListContextProps>(
  DriverListContextDefaults,
);

export const useDriverListContext = (): DriverListContextProps =>
  React.useContext(DriverListContext);

export const DriverListProvider: React.FC = ({ children }) => {
  const [driverList, setDrivers] = useState<DriverList | null>(null);

  const getDrivers = useCallback(async () => {
    try {
      const drivers = await getDriverList();

      setDrivers(drivers.data);
    } catch (error) {
      // Driver list fails silently as the app can still be used without the data
      console.error(error);
    }
  }, []);

  const getNameFromDriver = useCallback(
    (driver: AdditionalDriver) => {
      if (driver.id && driverList?.length) {
        const existingDriver = driverList.find(
          (additional) => additional.id === driver.id,
        );

        return `${existingDriver?.name}`;
      } else {
        return `${driver?.first_name} ${driver?.last_name}`;
      }
    },
    [driverList],
  );

  return (
    <DriverListContext.Provider
      value={{
        loading: !driverList,
        getDrivers,
        driverList,
        getNameFromDriver,
      }}
    >
      {children}
    </DriverListContext.Provider>
  );
};
