import React from 'react';

import cx from 'classnames';

import styles from './Text.module.scss';

interface TextProps {
  tag?: 'p' | 'span' | 'li' | 'label' | 'blockquote';
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  testId?: string;
  truncate?: boolean;
}

export const Text: React.FC<TextProps> = ({
  tag = 'p',
  className,
  size = 'md',
  testId,
  truncate = false,
  children,
}) => {
  const Component = tag;

  const textClasses = cx(styles['text'], styles[`text-${size}`], {
    [`${className}`]: className,
    [`${styles['text-truncate']}`]: truncate,
  });

  return (
    <Component className={textClasses} data-testid={testId}>
      {children}
    </Component>
  );
};
