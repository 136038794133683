import React, { useState } from 'react';

import { getAccountAPI, updateDriverAPI } from 'api/AccountAPI';
import { Account } from 'models/Account';

export interface DriverDetailsProps {
  firstName: string;
  lastName: string;
}
export interface AccountContextProps {
  account: Account | null;
  getAccount: () => Promise<void>;
  updateDriver: (driverDetails: DriverDetailsProps) => Promise<void>;
  updateLoading: boolean;
}

export const AccountContextValues = (): AccountContextProps => {
  const [account, setAccount] = useState<Account | null>(null);
  const [updateLoading, setUpdateLoading] = useState(false);

  const getAccount = async (): Promise<void> => {
    try {
      const res = await getAccountAPI();

      setAccount(res);
    } catch (error) {
      if (error.response.data?.message === 'Token has expired') {
        throw new Error(JSON.stringify(error.response.data, null, 2));
      } else {
        alert('Account not found. Please try again.');
        throw new Error(error);
      }
    }
  };

  const updateDriver = async (
    driverDetails: DriverDetailsProps,
  ): Promise<void> => {
    try {
      setUpdateLoading(true);

      await updateDriverAPI({
        name: `${driverDetails.firstName} ${driverDetails.lastName}`,
      });

      await getAccount();
    } catch (error) {
      console.error(error);
      alert('Failed to update driver. Please try again.');

      throw error; // Need this to propogate to the calling handler, so I can prevent redirects
    } finally {
      setUpdateLoading(false);
    }
  };

  return {
    account,
    getAccount,
    updateDriver,
    updateLoading,
  };
};

export const AccountContext = React.createContext<AccountContextProps>(
  {} as ReturnType<typeof AccountContextValues>,
);

export const useAccountContext = (): AccountContextProps =>
  React.useContext(AccountContext);

export const AccountProvider: React.FC = ({ children }) => {
  return (
    <AccountContext.Provider value={AccountContextValues()}>
      {children}
    </AccountContext.Provider>
  );
};
