import React, { useEffect, useRef } from 'react';

import CloseIcon from 'assets/icons/Close.svg';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ReactDOM from 'react-dom';

import styles from './Modal.module.scss';
import { Heading, IconButton } from '..';

export interface ModalProps {
  title?: string;
  testId?: string;
  hideHeader?: boolean;
  closeModal?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  title,
  testId,
  hideHeader,
  closeModal,
  children,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalRef.current) {
      window.scrollTo(0, 0);
      disableBodyScroll(modalRef.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={styles['modal']} data-testid={testId} ref={modalRef}>
      {!hideHeader && (
        <div className={styles['modal__header']}>
          <Heading>{title}</Heading>
          <IconButton
            icon={CloseIcon}
            onClick={closeModal}
            iconFillColor="accent"
            testId="close-modal"
          />
        </div>
      )}
      {children}
    </div>,
    document.body,
  );
};
