import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers';
import { PageContent } from 'components/PageContent';
import { WorkCard } from 'components/WorkCard';
import { ContainerWithActionFooter } from 'containers/ContainerWithActionFooter';
import { LayoutWithStepper } from 'containers/LayoutWithStepper';
import { RouteNames } from 'containers/Routes';
import { GC } from 'containers/Routes/Routes.constants';
import { useAccountContext } from 'contexts/AccountContext';
import { useDriverListContext } from 'contexts/DriverListContext';
import { useReportContext } from 'contexts/ReportContext';
import { useStepperContext } from 'contexts/StepperContext';
import { useAppMode } from 'hooks/useAppMode';
import { WorkType } from 'models/Report';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import styles from './ReviewWorkPage.module.scss';
import { reviewWorkPageSchema } from './reviewWorkPageSchema';

interface ReviewData {
  startDate: Date;
  endDate: Date | null;
  clearanceType: WorkType;
  uid: string;
}
interface SubmitData {
  report: ReviewData;
  additionalDrivers: ReviewData[];
}

export const ReviewWorkPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { appMode } = useAppMode();

  const { setCurrentStep } = useStepperContext();
  const { account } = useAccountContext();
  const { report, additionalDrivers, updateReport, updateAdditionalDriver } =
    useReportContext();
  const { getNameFromDriver, driverList, getDrivers } = useDriverListContext();

  useEffect(() => {
    if (!driverList && appMode === GC) {
      getDrivers();
    }
  }, [driverList, getDrivers, appMode]);

  const { handleSubmit, formState, control } = useForm({
    resolver: yupResolver(reviewWorkPageSchema()),
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      report: {
        startDate: report.start_date_time ?? '',
        endDate: report.end_date_time
          ? new Date(report.end_date_time).toISOString().substring(0, 16)
          : '',
        clearanceType: report.work_type ?? null,
      },
      additionalDrivers: additionalDrivers.map((driver) => ({
        startDate: driver.start_date_time
          ? new Date(driver.start_date_time).toISOString().substring(0, 16)
          : '',
        endDate: driver.end_date_time
          ? new Date(driver.end_date_time).toISOString().substring(0, 16)
          : '',
        clearanceType: driver.work_type,
        uid: driver.uid,
      })),
    },
  });
  const { fields } = useFieldArray<ReviewData>({
    control,
    name: 'additionalDrivers',
  });

  useEffect(() => {
    setCurrentStep(RouteNames.REVIEW_WORK);
  }, [setCurrentStep]);

  const onFormSubmit = (data: SubmitData) => {
    updateReport({
      work_type: data.report.clearanceType,
      start_date_time: data.report.startDate,
      end_date_time: data.report.endDate,
    });

    data.additionalDrivers.forEach((item) =>
      updateAdditionalDriver(item.uid, {
        work_type: item.clearanceType,
        start_date_time: item.startDate,
        end_date_time: item.endDate,
      }),
    );

    history.push(RouteNames.AFTER_PHOTOS);
  };

  return (
    <LayoutWithStepper>
      <form
        id="review-work-form"
        onSubmit={handleSubmit(onFormSubmit)}
        className={styles['review-work']}
        data-testid={'review-work'}
      >
        <ContainerWithActionFooter
          form="review-work-form"
          actionTestId="review-work-page-submit"
          label={t('reviewWork.action')}
          disabled={!formState.isValid}
        >
          <PageContent
            step={t('reviewWork.step', { step: appMode === GC ? '4' : '5' })}
            heading={t('reviewWork.title')}
            body={t('reviewWork.body')}
          />

          <WorkCard
            control={control}
            fieldName="report"
            driverName={account?.name ?? ''}
            isUser
            defaults={{
              startDate: report.start_date_time as unknown as string,
              endDate: report.end_date_time as unknown as string,
              clearanceType: report.work_type,
            }}
          />

          {fields.map((field, index) => (
            <WorkCard
              key={field.id}
              control={control}
              driverName={getNameFromDriver(additionalDrivers[index])}
              fieldName={`additionalDrivers[${index}]`}
              index={index}
              defaults={{
                startDate: field.startDate as unknown as string,
                endDate: field.endDate as unknown as string,
                clearanceType: field.clearanceType ?? null,
              }}
            />
          ))}
        </ContainerWithActionFooter>
      </form>
    </LayoutWithStepper>
  );
};
