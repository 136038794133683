import React, { useEffect, useState } from 'react';

import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { Routes } from 'containers/Routes';
import { AccountProvider } from 'contexts/AccountContext';
import { DriverListProvider } from 'contexts/DriverListContext';
import { JobProvider } from 'contexts/JobContext';
import { ReportProvider } from 'contexts/ReportContext';
import { StepperProvider } from 'contexts/StepperContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.scss';

const useAuthFromToken = () => {
  // This rather janky hook makes sure that the most recent token and workId are returned
  // from localstorage - unless a new one is supplied as a query param
  const params = new URLSearchParams(window.location.search);

  const paramToken = params.get('token') ?? '';
  const paramWorkId = params.get('workId') ?? '';

  const [, setToken] = useLocalStorage<string>('token', paramToken);
  const [, setWorkId] = useLocalStorage<string>('workId', paramWorkId);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (paramToken) setToken(paramToken);
    if (paramWorkId) setWorkId(paramWorkId);

    setReady(true);
  }, [paramToken, paramWorkId, setToken, setWorkId]);

  return ready;
};

export const App: React.FC = () => {
  const ready = useAuthFromToken();

  if (ready) {
    return (
      <ErrorBoundary>
        <JobProvider>
          <AccountProvider>
            <DriverListProvider>
              <ReportProvider>
                <StepperProvider>
                  <Router>
                    <Routes />
                  </Router>
                </StepperProvider>
              </ReportProvider>
            </DriverListProvider>
          </AccountProvider>
        </JobProvider>
      </ErrorBoundary>
    );
  }

  return null;
};
