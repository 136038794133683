import { apiClient } from 'api/utils';
import { Account, UpdateDriver } from 'models/Account';

import AccountApi from './AccountAPI.constants';

export const getAccountAPI = async (): Promise<Account> => {
  const response = await apiClient.get(AccountApi.GET_ACCOUNT);

  return response.data.data;
};

interface UpdateDriverProps {
  name: string;
}

export const updateDriverAPI = async (
  update: UpdateDriverProps,
): Promise<UpdateDriver> => {
  const response = await apiClient.put(AccountApi.UPDATE_DRIVER, update);

  return response.data.data;
};
