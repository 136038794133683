import React from 'react';

import cx from 'classnames';
import { Label } from 'components/common/Label';
import { Checkbox as ReakitCheckbox } from 'reakit/Checkbox';

import styles from './Checkbox.module.scss';

interface CheckboxProps {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  size?: 'sm' | 'md' | 'lg';
  testId?: string;
  value?: string;
  label?: string;
  name?: string;
  variant?: 'default' | 'accent' | 'positive' | 'negative';
  align?: 'center' | 'start';
}

const CheckboxRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  CheckboxProps
> = (
  {
    className,
    checked = false,
    disabled = false,
    size = 'md',
    testId,
    value,
    label,
    variant = 'positive',
    name,
    align,
    onChange,
  },
  ref,
) => {
  const classes = cx(
    styles['input__checkbox'],
    styles[`input__checkbox--${size}`],
    styles[`input__checkbox--${variant}`],
    {
      [styles['checked']]: checked,
      [styles['disabled']]: disabled,
    },
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.checked);
  };

  return (
    <Label
      size={size}
      className={cx(
        styles['input__checkbox-label'],
        {
          [styles['start-align']]: align === 'start',
        },
        className,
      )}
      testId={testId ? `${testId}-label` : undefined}
    >
      <ReakitCheckbox
        data-testid={testId}
        className={classes}
        checked={checked}
        onChange={handleOnChange}
        value={value}
        name={name}
        ref={ref}
      />
      {label}
    </Label>
  );
};

export const Checkbox = React.forwardRef(CheckboxRef);
