import React from 'react';

import { getDriverWorkAPI } from 'api/DriverWorkAPI';
import { Job } from 'models/Job';

export interface JobContextProps {
  getJob: (workId: string) => Promise<void>;
  job: Job | null;
  setJob: (state: Job) => void;
}

export const JobContextValues = (): JobContextProps => {
  const [job, setJob] = React.useState<Job | null>(null);

  const getJob = async (workId: string): Promise<void> => {
    try {
      const result = await getDriverWorkAPI(workId);
      setJob(result);
    } catch (error) {
      if (error.response.data.message === 'Token has expired') {
        throw new Error(JSON.stringify(error.response.data, null, 2));
      } else {
        alert('Job not found. Please try again.');
        throw new Error(error);
      }
    }
  };

  return {
    getJob,
    job,
    setJob,
  };
};

export const JobContext = React.createContext<JobContextProps>(
  {} as ReturnType<typeof JobContextValues>,
);

export const useJobContext = (): JobContextProps =>
  React.useContext(JobContext);

export const JobProvider: React.FC = ({ children }) => {
  return (
    <JobContext.Provider value={JobContextValues()}>
      {children}
    </JobContext.Provider>
  );
};
