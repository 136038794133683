import React from 'react';

import { postFinalReport } from 'api/FinalReportAPI';
import { ImageUploadItem } from 'components/common';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useWorkId } from 'hooks/useWorkId';
import { AdditionalDriver, ReportData } from 'models/Report';
import { serialize } from 'object-to-formdata';
import { formatDate, generateID } from 'utils';

export interface ReportContextProps {
  report: ReportData;
  setReport: (state: ReportData) => void;
  additionalDrivers: AdditionalDriver[];
  setAdditionalDrivers: (state: AdditionalDriver[]) => void;
  addAdditionalDriver: (driver: AdditionalDriver) => void;
  removeAdditionalDriver: (uid: string) => void;
  updateAdditionalDriver: (uid: string, driver: AdditionalDriver) => void;
  updateReport: (update: ReportData) => void;
  submitWorkReport: (media: ImageUploadItem[]) => void;
}

export const ReportContextValues = (): ReportContextProps => {
  const { workId } = useWorkId();

  const [report, setReport] = useLocalStorage<ReportData>(
    `report[${workId}]`,
    {},
  );

  const [additionalDrivers, setAdditionalDrivers] = useLocalStorage<
    AdditionalDriver[]
  >(`drivers[${workId}]`, []);

  const updateReport = (update: ReportData) => {
    setReport((state: ReportData) => ({
      ...state,
      ...update,
    }));
  };

  const addAdditionalDriver = (driver: AdditionalDriver) => {
    setAdditionalDrivers((state: AdditionalDriver[]) => [
      ...state,
      {
        ...driver,
        uid: generateID(),
        start_date_time: new Date(),
      },
    ]);
  };

  const removeAdditionalDriver = (uid: string) => {
    setAdditionalDrivers((state: AdditionalDriver[]) => {
      return state.filter((driver) => driver.uid !== uid);
    });
  };

  const updateAdditionalDriver = (uid: string, driver: AdditionalDriver) => {
    const driverIndex = additionalDrivers.findIndex(
      (driverItem) => driverItem.uid === uid,
    );

    additionalDrivers[driverIndex] = {
      ...additionalDrivers[driverIndex],
      ...driver,
    };

    setAdditionalDrivers([...additionalDrivers]);
  };

  const submitWorkReport = async (media: ImageUploadItem[]) => {
    const data = {
      ...report,
      start_date_time: formatDate(report.start_date_time),
      end_date_time: formatDate(report.end_date_time),
      media: media.map((item) => item.file),
      // uid can't be sent to the API, so we remove it through destructuring and then ignore the warning
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      additional_drivers: additionalDrivers.map(({ uid, ...driver }) => ({
        ...driver,
        start_date_time: formatDate(driver.start_date_time),
        end_date_time: formatDate(driver.end_date_time),
      })),
      completion_step: '5',
    };

    const formData = serialize(data, { indices: true });

    await postFinalReport({ formData, workId });
  };

  return {
    report,
    setReport,
    updateReport,
    additionalDrivers,
    setAdditionalDrivers,
    addAdditionalDriver,
    removeAdditionalDriver,
    updateAdditionalDriver,
    submitWorkReport,
  };
};

export const ReportContext = React.createContext<ReportContextProps>(
  {} as ReturnType<typeof ReportContextValues>,
);

export const useReportContext = (): ReportContextProps =>
  React.useContext(ReportContext);

export const ReportProvider: React.FC = ({ children }) => {
  return (
    <ReportContext.Provider value={ReportContextValues()}>
      {children}
    </ReportContext.Provider>
  );
};
