import React from 'react';

import cx from 'classnames';
import colors from 'designSystem/Colors.module.scss';
import { generateID } from 'utils';

import styles from './Icon.module.scss';

export type IconColorVariant =
  | 'primary'
  | 'blanc'
  | 'accent'
  | 'positive'
  | 'negative';

type IconProps = {
  className?: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  srLabel?: string;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  testId?: string;
  fillColor?: IconColorVariant;
  strokeColor?: IconColorVariant;
};

export const Icon: React.FC<IconProps> = ({
  /** props freetext classes */
  className,
  /** icon passed from parent import via CRA's internal SVGR */
  icon,
  /** a11y 'label' for accessibility */
  srLabel,
  /** icon size */
  size = 'md',
  /** testing id */
  testId,
  /** icon fill colour */
  fillColor,
  /** icon stroke colour */
  strokeColor,
}) => {
  const iconClasses = cx(styles['icon'], styles[`icon--${size}`], className);

  const Component = icon;
  const uuid = generateID();

  return (
    <span
      className={iconClasses}
      data-testid={!!testId ? `container-${testId}` : null}
    >
      <Component
        className={styles['icon-svg']}
        data-testid={!!testId ? testId : null}
        {...(fillColor && {
          fill: colors[fillColor],
        })}
        {...(strokeColor && {
          stroke: colors[strokeColor],
        })}
        {...(srLabel && {
          'aria-describedby': uuid,
        })}
      />
      {srLabel && (
        <span
          id={uuid}
          className="sr-only"
          data-testid={!!testId ? `sr-${testId}` : null}
        >
          {srLabel}
        </span>
      )}
    </span>
  );
};
