import React from 'react';

import cx from 'classnames';
import { Label } from 'components/common/Label';

import styles from './RadioGroup.module.scss';

interface RadioOptions {
  label: string;
  value: string;
}
interface RadioGroupProps {
  className?: string;
  disabled?: boolean;
  labelBy?: string;
  onChange?: (value: string) => void;
  orientation?: 'horizontal' | 'vertical';
  size?: 'sm' | 'md' | 'lg';
  testId?: string;
  value?: string;
  options: RadioOptions[];
  variant?: 'default' | 'accent' | 'positive' | 'negative';
  name?: string;
}

const RadioGroupRef: React.ForwardRefRenderFunction<
  HTMLInputElement,
  RadioGroupProps
> = (
  {
    className,
    disabled = false,
    orientation,
    labelBy,
    onChange,
    size = 'md',
    testId,
    value,
    options,
    variant = 'positive',
    name,
  },
  ref,
) => {
  const classes = cx(`${styles['input__radio-group']}`, {
    [`${styles[`input__radio-group--${variant}`]}`]: variant,
    [`${styles[`input__radio-group--${size}`]}`]: size,
    [`${styles[`input__radio-group--${orientation}`]}`]: orientation,
    [`${styles['disabled']}`]: disabled,
    [`${className}`]: className,
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(e.target.value);
  };

  return (
    <div
      role="radiogroup"
      data-testid={testId}
      className={classes}
      aria-label={labelBy}
    >
      {options.map((option, i) => (
        <Label
          testId={testId ? `${testId}-label` : undefined}
          className={styles['input__radio-label']}
          key={i}
          size={size}
        >
          {option.label}
          <input
            type="radio"
            className={styles['input__radio']}
            disabled={disabled}
            data-testid={testId ? `${testId}-radio` : undefined}
            onChange={handleOnChange}
            value={option.value}
            defaultChecked={value === option.value}
            name={name}
            ref={ref}
          />
          <span
            data-testid={testId ? `${testId}-circle` : undefined}
            className={styles['input__radio-circle']}
          />
        </Label>
      ))}
    </div>
  );
};

export const RadioGroup = React.forwardRef(RadioGroupRef);
